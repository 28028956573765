.delete, .remove {
	color: #d33724;
	&:hover {
		color: #f56954;
	}	
}

.form-action {
	a {
		margin: 0 1px;
	}
}

.form-button {
	button {
		margin-right: 10px;
	}
}

#modal-confirm {
	button {
		min-width: 80px;
		&.close {
			min-width: auto;
		}
	}

}

.file-upload, .file-upload-multiple {
	position:absolute;
	z-index:2;
	top:0;
	left:0;
	filter: alpha(opacity=0);
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity:0;
	background-color:transparent;
	color:transparent;
}

.upload-file-info.multiple {
	margin-right: 10px;
}

.image-preview {
	max-width: 150px;
}

.vt-middle {
	vertical-align: middle !important;
}

table img {
	max-height: 100px;
	width: auto;
}

.sel-catalog {
	height: 100px;
	text-align: center;
}
.img-catalog {	
	max-height: 100px;
	width: auto;
}

.gly-spin {
	-webkit-animation: spin 2s infinite linear;
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
	}
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
	}
}
@-o-keyframes spin {
	0% {
		-o-transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(359deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}